import React, { useEffect, useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react"; // Importez useAuth0
import NotFound from "pages/NotFound";
import Darksplashscreen from "pages/Darksplashscreen";
import DarkwalkthroughTwo from "pages/DarkwalkthroughTwo";
import DarkwalkthroughThree from "pages/DarkwalkthroughThree";
import DarkwalkthroughFour from "pages/DarkwalkthroughFour";
import Darkwelcomescreen from "pages/Darkwelcomescreen";
import BoiteAOutil from "pages/BoiteAOutil";
import ImaginePage from "pages/Imagine";
import ExplorePage from "pages/Explore";
import CategoryPage from "pages/Category";
import DetourerPage from "pages/Detourer";
import ProfilPage from "pages/Profil";
import ErrorPage from "pages/Error";
import RGPDPage from "pages/RGPD";
import ContactPage from "pages/Contact";

const ProtectedRoute = ({ page: Page }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authorizedEmails, setAuthorizedEmails] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  
  if (isLoading) {
    //return <div>Loading...</div>; // Vous pouvez remplacer cela par un composant de chargement personnalisé
  }

  /*useEffect(() => {
    // Charger les adresses e-mail autorisées depuis le fichier JSON
    fetch("http://localhost:3000/authorizedEmails.json") // Assurez-vous que ce chemin est correct
      .then(response => response.json())
      .then(data => setAuthorizedEmails(console.log(data.authorizedEmails)));
  }, []);

  
  useEffect(() => {
    if (isAuthenticated && user) {
      // Vérifier si l'adresse e-mail de l'utilisateur est dans la liste des adresses autorisées
      setIsAuthorized(authorizedEmails.includes(user.email));
    }
  }, [isAuthenticated, user, authorizedEmails]);*/
  

  if (isAuthenticated && (
    user.email == "admin@agartha.ch" ||
    user.email == "joao@agartha.ch" ||
    user.email == "hendy.webpro@gmail.com" ||
    user.email == "frederique.poret@champagne-dumenil.com" ||
    user.email == "contact@cave-cqfd.com" ||
    user.email == "acaroni@gmail.com" ||
    user.email == "test@macuvee.ch" ||
    user.email == "michael@boudot.me" ||
    user.email == "contact@msstudio.fr" ||
    user.email == "studio@boudot.me" ||
    user.email == "dailyjnh@gmail.com" ||
    user.email == "louis.debonnecaze@intercellar.io" ||
    user.email == "maxime.garraud@intercellar.io" ||
    user.email == "loriot.charlene@gmail.com" 
  )) {
    return <Page />;
  } else if (isAuthenticated) {
    return <Navigate to="/error" />;
  } else {
    return <Navigate to="/darkwelcomescreen" />;
  }
};

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Darksplashscreen /> },
    { path: "*", element: <NotFound /> },
    { path: "darksplashscreen", element: <Darksplashscreen /> },
    { path: "darkwalkthroughtwo", element: <DarkwalkthroughTwo /> },
    { path: "darkwalkthroughthree", element: <DarkwalkthroughThree /> },
    { path: "darkwalkthroughfour", element: <DarkwalkthroughFour /> },
    { path: "darkwelcomescreen", element: <Darkwelcomescreen /> },
    { path: "error", element: <ErrorPage /> },
    { path: "RGPD", element: <RGPDPage /> },
    { path: "Contact", element: <ContactPage /> },
    { path: "imagine", element: <ProtectedRoute page={ImaginePage} /> },
    { path: "boiteaoutil", element: <ProtectedRoute page={BoiteAOutil} /> },
    { path: "explore", element: <ProtectedRoute page={ExplorePage} /> },
    { path: "category", element: <ProtectedRoute page={CategoryPage} /> },
    { path: "detourer", element: <ProtectedRoute page={DetourerPage} /> },
    { path: "profil", element: <ProtectedRoute page={ProfilPage} /> },
  ]);

  return element;
};

export default ProjectRoutes;